.document-main {
    margin-top: 100px;
}

.document-main {
    margin-top: 100px;
}

@media (max-width: 600px) {
    .document-main {
        padding: 15px !important;
    }
}
.school-main-heading{
    margin: 32px;
    width: calc(100% - 64px);
    color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 126.667% */
  
  }
  .school-data-heading{
    padding: 8px 24px;
    width: 100%;
    border-top:1px solid var(--gray-200, #EAECF0);
    .heading-data{
      color: var(--gray-900, #101828);
  
  /* Text lg/Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
    }
  }
  .school-data-heading input {
    display: flex;
  width: 320px;
  flex-direction: column;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--White, #FFF);
  
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
