.about-main-heading {
  margin: 32px;
  width: calc(100% - 64px);
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 126.667% */
}

.about-teaxtarea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--White, #fff);
  width: 95%;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin: 0px 32px;
  min-height: 300px !important;
  padding: 12px;
}

.current-about {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px;
  width: 95%;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin: 21px 32px;
  padding: 20px;
}

.about-btn {
  display: flex;
  width: fit-content; /* Adjust width to fit content */
  margin: 32px 32px 0 auto;
  height: 50px;
  /* margin: 32px; */
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #f2e03a !important;
  background: #f2e03a !important;
  color: #000 !important;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.procedure-main {
  margin-top: 100px;
}

.textarea-height.form-control {
  height: 300px;
  padding: 15px;
}

.textarea {
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
}

.input-wrap {
  position: relative;
}

.input-wrap .input {
  position: absolute;
  width: 100%;
  left: 0;
}

.width-machine {
  padding: 0 1rem;
}

.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
}
