.school-creation-main-heading {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}
.create-school-text {
  color: var(--gray-900, #101828);

  /* Text lg/Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
.form-wrap {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  background: var(--White, #fff);
}
.custom-input {
  margin-bottom: 24px;
}
.title-lable {
  color: var(--gray-700, #344054);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.school-main .custom-input input {
  background: #ffecdf;
  border: 1px solid #f2d6c2;
  border-radius: 15px;
  box-shadow: none;
  padding: 0.6rem 0.75rem;
  resize: none;
  color: #000000;
  width: 100%;
  outline: none;
}

.school-main .custom-input select {
  background: #ffecdf;
  border: 1px solid #f2d6c2;
  border-radius: 15px;
  box-shadow: none;
  padding: 0.6rem 0.75rem;
  resize: none;
  color: #000000;
  width: 100%;
  outline: none;
}

.custom-file {
  position: relative;
  width: 100%;
  height: 130px;
  border-radius: 15px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--White, #fff);
}

.custom-file__input {
  width: 100%;
  height: 100%;
  padding: 12px;
}

.custom-file__input::-webkit-file-upload-button {
  display: none;
}

.custom-file__label {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 15px;
  line-height: 59px;
  padding: 20px;
  color: var(--gray-500, #667085);
  .upload-text {
    color: var(--gray-500, #667085);
    text-align: center;

    /* Text xs/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }

  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: center;
}

.custom-file__input:valid ~ .custom-file__label {
  background: #fff;
  display: none;
}

.custom-file span {
  font-size: 13px;
}

.submit-btn {
  background: linear-gradient(
    180.35deg,
    #ffc051 0.29%,
    #fa803c 52.38%,
    #f86333 99.69%
  );
  box-shadow: none !important;
  box-shadow: 0px 8px 30px rgba(249, 111, 54, 0.6);
  border-radius: 25px;
  font-weight: 500;
  font-size: 22px;
  color: #ffffff;
  border: none;
  margin-top: 50px;
  padding: 15px 50px;
}

.container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.image {
  display: block;
  width: 100%;
  height: 130px;
  /* height: auto; */
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.3s ease;
  background-color: rgb(189 185 185);
}

.container:hover .overlay {
  opacity: 1;
}

.icon {
  top: 40%;
  left: 50%;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 100px;
  text-align: center;
  position: absolute;
  background-color: transparent;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
