.chat-main-heading{
    margin: 32px;
    width: calc(100% - 64px);
    color: var(--gray-900, #101828);
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 126.667% */
}
.chat-inner-heading{
    display: flex;
height: 44px;
padding: 12px 24px;
align-items: center;
gap: 12px;
align-self: stretch;
    color: var(--gray-500, #667085);
    border-bottom: 1px solid var(--gray-200, #EAECF0);
background: var(--White, #FFF);

/* Text xs/Medium */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
}
.user-name {
    color: var(--gray-900, #101828);

    /* Text sm/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  .user-email {
    color: var(--gray-500, #667085);

    /* Text sm/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
.main-chat{
    width: 100%;
    border-top: 1px solid var(--gray-200, #EAECF0);
}

.main-chat .chat-box-scroll {
    height: calc(100vh - 180px);
    overflow-y: auto;
    scrollbar-width: none;
}
.chat-window{
    background: var(--White, #FFF);
    border-left: 1px solid var(--gray-200, #EAECF0);
}
.chat-header{
    display: flex;
padding: 20px 24px 19px 24px;
align-items: center;
gap: 16px;
flex: 1 0 0;
border-bottom: 1px solid var(--gray-200, #EAECF0);

}

.main-chat .chat-window-scroll {
    height: calc(100vh - 180px);
    overflow-y: auto;
    /* margin: 10px 0; */
    scrollbar-width: none;
    padding: 24px;
}
.chat-inner{
    margin-bottom: 20px;
    .chat-user-heading-div{
        max-width: 450px;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chat-user-name{
        color: var(--gray-700, #344054);

        /* Text sm/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }
    .chat-time{
        color: var(--gray-500, #667085);

        /* Text xs/Regular */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }
}
}
.chat-user-text-left{
     max-width: 450px;
    max-width: 450px;
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px 0px 8px 8px;
background: var(--brand-600, #7F56D9);

/* Shadow/sm */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
.msg-p{
    color: var(--White, #FFF);

/* Text md/Regular */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
}
.chat-user-text-right{
        min-width: 150px;
    max-width: 450px;
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px 0px 8px 8px;
    border-radius: 0px 8px 8px 8px;
background: var(--gray-100, #F2F4F7);

/* Shadow/sm */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
.msg-p{
    color: var(--gray-900, #101828);

    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
}
/* 
.main-chat .chat-window-scroll::-webkit-scrollbar,
.main-chat .chat-box-scroll::-webkit-scrollbar {
    width: 4px;
} */

/* .main-chat .search {
    background: #FFFFFF;
    border: 0.5px solid #FFCAA4;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    height: 57px;
    outline: none;
    padding: 15px 50px 15px 20px;
    background-image: url(../../../public/images/search_icon.png);
    background-position: calc(100% - 22px) 18px, calc(100% - 20px) 14px, 100% 0;
    background-size: 20px;
    background-repeat: no-repeat;
}             */

 .main-chat .chat-box-scroll .chat-bar {
    display: flex;
    height: 72px;
    padding: 16px 24px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200, #EAECF0);
    background: var(--gray-50, #F9FAFB);
}
/*
.main-chat .chat-box-scroll .chat-bar h6 {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 224px;

} */

/* .main-chat .chat-box-scroll .chat-bar p {
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    color: #8B7768;
    padding-top: 5px;
    max-width: 225px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */

.main-chat .chat-box-scroll .chat-bar .chat-img {
    height: 55px;
    min-width: 55px;
    width: 55px;
    object-fit: cover;
    border-radius: 50%;
}

.main-chat .chat-box-scroll .chat-bar span {
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}

.main-chat .chat-window .chat-header {
    display: flex;
    align-items: center;
}

.main-chat .chat-window .chat-header .backrow {
    float: left;
    background-color: transparent;
    font-size: 25px;
}

.main-chat .chat-window .chat-header h6 {
    text-align: center;
    margin: auto;
    font-weight: 500;
    font-size: 28px;
    text-transform: capitalize;
    color: #000000;
    word-break: break-all;
}

/* .main-chat .chat-window .chat-inner {
    display: block;
    border-radius: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px;
    max-width: 450px;
    width: 100%;
    border: 1px solid #EFAD80;
} */

.main-chat .chat-window .chat-right .chat-inner {
    background: #FFFFFF;
}


/* .main-chat .chat-window .chat-left .chat-inner {
    background: #FDDBC4;
} */




.main-chat .chat-window .chat-inner .chat-img {
    height: 55px;
    min-width: 55px;
    width: 55px;
    object-fit: cover;
    border-radius: 50%;
}

.main-chat .chat-window .chat-right {
    display: flex;
    justify-content: flex-start;
    padding-right: 15px;
}

.main-chat .chat-window .chat-left {
    display: flex;
    justify-content: flex-end;
    padding-left: 15px;
}

#breakword {
    max-width: 339px;
    padding-right: 5px;
    word-break: break-all;

}

@media (max-width:1600px) {
    .main-chat .chat-box-scroll .chat-bar h6 {
        font-size: 18px;
        max-width: 224px;
    }

    .main-chat .chat-box-scroll .chat-bar .chat-img {
        height: 45px;
        min-width: 45px;
        width: 45px;
    }

    .main-chat .chat-box-scroll .chat-bar p {
        padding-top: 2px;
    }
}

@media (max-width:1300px) {
    .main-chat .chat-window .chat-inner {
        max-width: 400px;
        margin-bottom: 15px;
        /* padding: 10px; */
    }

    .main-chat .chat-window .chat-inner .chat-img {
        height: 50px;
        min-width: 50px;
        width: 50px;
    }

    .main-chat .chat-window .chat-left {
        padding-left: 35px;
    }

    .main-chat .chat-window .chat-right {
        padding-right: 35px;
    }

    .main-chat .chat-window .chat-inner h6 {
        font-size: 18px;
    }

    .main-chat .chat-window .chat-inner p {
        padding-top: 2px;
    }

    .main-chat .chat-box-scroll .chat-bar {
        /* padding: 10px; */
    }
}

@media (max-width:768px) {
    .main-chat .chat-box-scroll .chat-bar .chat-img {
        height: 45px;
        width: 45px;
    }

    .main-chat .chat-window .chat-inner .chat-img {
        height: 45px;
        width: 45px;
        min-width: 45px;
    }

    .main-chat .chat-window .chat-header h6 {
        font-size: 24px;
    }
}

.new-h6 {
    width: 100%;
    display: flex;
    justify-content: space-between;

}
.icon-container {
    position: relative;
    height: fit-content;
  }
  .status-circle {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1.5px solid var(--White, #FFF);
    background: var(--success-500, #12B76A);
    bottom: 0;
    right: 0;
    position: absolute;
  }
  