.login {
  height: 100vh;
  background-image: url("../../assets/img/Desktop.jpg"); /* Specify the path to your image */
  background-size: cover; /* Adjusts the image size to cover the entire div */
  background-position: center; /* Center the image within the div */
  background-repeat: no-repeat;
  /* background: linear-gradient(180.35deg, #ffc0514d, #f8633373); */
}

.login-box {
  display: flex;
  min-width: 408px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.heading {
  color: var(--gray-900, #101828);
  text-align: center;
  margin-bottom: -15px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.login-form {
  width: 100%;
  label {
    color: var(--gray-700, #344054);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.login-box input {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--White, #fff);
  margin: 8px 0px 20px 0px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.login-btn {
  display: flex;
  width: 100%;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #f2e03a !important;
  background: #f2e03a !important;
  color: #000 !important;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
