.dashboard-header {
  margin: 32px;
}

.dashboard-header .user-heading {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
}

.dashboard-header .user-text {
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.cards-wrap,
.users-wrap {
  margin: 0 32px;
  width: calc(100% - 64px);
}

.category-box,
.users-wrap input {
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.category-box {
  border: 1px solid var(--gray-200, #eaecf0);
}

.category-box .card-title,
.category-box .card-count,
.category-box .progress,
.users-wrap .user-heading {
  color: var(--gray-900, #101828);
  font-family: Inter;
}

.category-box .card-title,
.users-wrap .user-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.category-box .card-count {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.category-box .progress,
.users-wrap input {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: white;
}

.users-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px;
}

.users-wrap input {
  width: 320px;
  padding: 10px 14px;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
