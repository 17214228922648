@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-BlackItalic.woff2") format("woff2"),
    url("assets/font/Lato-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Bold.woff2") format("woff2"),
    url("assets/font/Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Black.woff2") format("woff2"),
    url("assets/font/Lato-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("assets/font/Lato-Hairline.woff2") format("woff2"),
    url("assets/font/Lato-Hairline.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Italic.woff2") format("woff2"),
    url("assets/font/Lato-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-LightItalic.woff2") format("woff2"),
    url("assets/font/Lato-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Regular.woff2") format("woff2"),
    url("assets/font/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("assets/font/Lato-HairlineItalic.woff2") format("woff2"),
    url("assets/font/Lato-HairlineItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-BoldItalic.woff2") format("woff2"),
    url("assets/font/Lato-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Light.woff2") format("woff2"),
    url("assets/font/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Inter";
  background-color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

.dashboard-main {
  width: calc(100% - 250px);
  margin-left: 250px;
  transition: 0.5s;
  background-color: #000;
}

.input-field label {
  font-weight: 500;
  font-size: 16px;
  color: #afafaf;
  width: 100%;
}

.input-field input,
.input-field select {
  background: #f2f2f2;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 15px 20px;
  width: 100%;
}

.select-file input {
  background: #f2f2f2;
  border-radius: 10px;
  outline: none;
  border: 1px dashed #000;
  padding: 15px 20px;
  width: 100%;
  width: 279px;
  max-width: 100%;
}

.modal {
  padding-top: 30px;
  padding-bottom: 30px;
}

.modal-title h2 {
  font-weight: 600;
  font-size: 35px;
  color: #000000;
}

.modal-close-btn {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
  color: #000000 !important;
  font-size: 40px;
  padding: 0;
  position: absolute;
  top: -34px;
  right: -23px;
}

.modal-dialog {
  width: 830px !important;
  max-width: 100% !important;
  margin: 0 auto !important;
}

#modal-user {
  width: calc(100% - 15px) !important;
  max-width: 1440px !important;
}

.modal-content {
  border-radius: 30px;
  background: #fff4ec;
}

.modal-body {
  padding: 2rem;
}

.modal-btn-group .cancel {
  background: #eeeeee;
  border-radius: 10px;
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  border: none;
  box-shadow: none;
  padding: 12px 20px;
}

.modal-btn-group .ok {
  background: #8f8f8f;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border: none;
  box-shadow: none;
  padding: 12px 50px;
}

.modal_superlative .modal-content {
  overflow: hidden;
  background: #ffffff;
  border: 2px solid #f97639;
}

.modal_superlative .modal-content .modal-header {
  background: linear-gradient(180.35deg, #ffc051 0.29%, #f86333 99.69%);
  border-radius: 30px 30px 0 0;
  position: relative;
}

.modal_superlative .modal-content .modal-header .btn-close {
  position: absolute;
  right: 30px;
  top: 38px;
  content: "";
  border-radius: 50%;
  opacity: 1;
  background-image: url(/public/images/btnclose.png);
  padding: 0;
  transform: scale(2);
}

.modal_superlative .modal-content .form-label,
.form-label {
  color: #000000;
}

.modal_superlative .modal-content .form-control {
  background: #ffecdf;
  border: 1px solid #f2d6c2;
  border-radius: 15px;
  box-shadow: none;
  padding: 0.6rem 0.75rem;
  resize: none;
  height: 55px;
}

.modal_superlative .modal-content .submit-btn {
  background: linear-gradient(
    180.35deg,
    #ffc051 0.29%,
    #fa803c 52.38%,
    #f86333 99.69%
  );
  box-shadow: 0px 8px 30px rgba(249, 111, 54, 0.6);
  border-radius: 20px;
  width: 422px;
  max-width: 100%;
  height: 60px;
  font-weight: 900;
  font-size: 19px;
  text-decoration: none;
  display: block;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 15px;
  border: none;
}

.modal_superlative .modal-content .modal-header .btn-close:focus {
  box-shadow: none;
}

.modal_superlative .modal-content .modal-header .modal-title {
  margin: auto;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 991px) {
  .modal_superlative .modal-content .modal-header {
    padding: 12px 0;
  }

  .modal_superlative .modal-content .modal-header .modal-title {
    font-size: 24px;
  }

  .modal_superlative .modal-content .submit-btn {
    height: 50px;
    font-size: 18px;
    margin: 30px auto 0;
  }

  .modal_superlative .modal-content .form-control {
    height: 45px;
  }

  element.style {
  }

  .modal_superlative .modal-content .modal-header .btn-close {
    top: 31px;
    transform: scale(1.7);
  }
}

@media (max-width: 1024px) {
  .dashboard-main {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 690px) {
  .select-field {
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  .select-file {
    flex-direction: column;
    justify-content: center;
  }

  .select-file p {
    text-align: center;
  }
}
.home-main {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 40px 0px 0px 40px;
  background: var(--White, #fff);
  min-height: calc(100vh - 6px);
  overflow: hidden;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 2.65rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.custom-data-table {
  width: 100%;
  .table-heading {
    height: 44px;
    padding: 12px 24px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200, #eaecf0);
    background: var(--White, #fff);
    color: var(--gray-500, #667085);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    width: max-content;
  }
  .even-row {
    height: 72px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200, #eaecf0);
    background: var(--gray-50, #f9fafb);
  }
  .odd-row {
    height: 72px;
    padding: 16px 24px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200, #eaecf0);
    background: white;
  }
  .table-data {
    color: var(--gray-500, #667085);
    background: none;
    border-bottom: none;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 16px 24px;
    vertical-align: middle;
    .user-name {
      color: var(--gray-900, #101828);

      /* Text sm/Medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .user-email {
      color: var(--gray-500, #667085);

      /* Text sm/Regular */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}
.pagination-wrap {
  display: flex;
  width: 100%;
  padding: 12px 24px 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--gray-200, #eaecf0);
  .prev-next-btn {
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--White, #fff);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .pagination-count {
    color: var(--gray-700, #344054);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}
.custom-delete-dialog {
  width: 400px;
  padding: 24px;
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  .heading {
    color: var(--gray-900, #101828);
    text-align: center;

    /* Text lg/Semibold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }
  .data {
    color: var(--gray-500, #667085);
    text-align: center;

    /* Text sm/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .dialog-btn {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--White, #fff);
    text-transform: capitalize;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}
.custom-btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #f2e03a;
  background: #f2e03a;
  text-transform: capitalize;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #000;

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.custom-cancel-btn {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.custom-input {
  margin-bottom: 24px;
}
.title-lable {
  color: var(--gray-700, #344054);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.add-more-btn {
  color: #0085ff;
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 200% */
}
.added-name {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 200% */
}
.group-image-heading {
  text-align: center;
  color: var(--gray-700, #344054);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
